<template>
  <div class="book-detail">
    <p class="title">管理体系数字化转型实践</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >书中提出数字经济下的数字体系概念，进而引申出先进体系的建设方法和思维方式，并提出一种普适性的数字体系建设方法，使企业数字化转型具有操作性，同时从结构设计开始的观点，介绍了从管理模式展开的经营路线和新型价值链两个关键的顶层结构。同时，由于数字体系与智能制造密不可分，书中给出了数字体系建设和转型的方向，并率先研究智能化制造与数字化体系的关系。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <p>来自中国社科院所长李平序</p>
      <span
        >数字化热潮席卷全球。为迎合这一大趋势，不少组织正筹划转型。可是，我们留意到，许多组织并不清楚“为何转”
        “转什么"？“怎样转"。企业去参加一些行业会议、第三方发布会或者交流会，接触到各种数字化转型的概念或方案。做大数据的企业跟你讲，要建数据库，要做数据治理；软件提供商会说，要上信息系统，比如MES（生产制造执行系统）、PLM（产品生命周期管理系统）等；专门搭建中台的公司认为，需要学阿里巴巴、腾讯或者华为等先进的企业去建设中台系统；做云计算的企业提出，要把整个组织云台化，引入PaaS（平台即服务）。当然还有许多数字化转型的其他领域，如做数字营销的、做智能制造的、做自动化设备的······很多组织面对众多选择一下子就很迷茫了。再深入了解已经进行了数字化转型的企业案例，发现每家的做法差别很大，到底哪一种才真正适合自己的企业呢？就更困惑了！其实，所有这些方案都离不开一个基础性的转型一个管理体系的数字化转型。</span
      >
      <br />
      <br />
      <span
        >我之所以为《管理体系数字化转型实践》一书作序，是因为在众多组织面临数字化转型时，本书提供的实践和方法值得参考。特别强调其中三点。</span
      >
      <br />
      <br />
      <span>
        第一点，管理体系（制度）的数字化转型是组织所有数字化转型的根本。一个国家、一个组织都是以体系为基础的，体系也被视为生产力发展的保障。因此，只有从体系上进行数字化转型才能确保各种业务数字化转型的彻底性。体系是以复杂性为特征的，而数字化则是解决复杂性最好的途径。我相信，当体系与智能手机和平台一体化后，任何复杂操作都变得简单。此外，一切体系变革都围绕效率两字，现在看来没有比数字化更好的变革手段了，我们从二维码操作中就已经深刻感受到了数字化震撼的效率威力。
      </span>
      <br />
      <br />
      <span
        >第二点，体系碎片化、单元化，这提供了体系变革的新思路。过去组织都习惯性地将体系编辑成厚厚的文件本，使体系落地困难。有了数字化手段后，就能用数字描述体系和流程的面相或作业场景，改变了传统文件的表达方法一一
        一种可拆分和可还原的数字方式，并且可以将体系碎片通过平台精准地分配到每一个人的作业端上。这正迎合了互联网时代碎片化作业的特点，是保证体系落地的新思维。</span
      >
      <br />
      <br />
      <span
        >第三点，将数字体系与智能制造体系融合在一起。智能制造本身就是最具代表性的数字化体系，很多组织将智能制造看成数字化转型的重点。数字体系的目标是管理智能化，而智能制造的目标是制造智能化，两者都是以数字技术为基础的，并且成熟度可以互补，相互协同推进、迭代升级。</span
      >
      <br />
      <br />
      <span
        >数字化转型是国际大趋势，是我国的战略举措，国家和地方政府出台各种鼓励政策，目的是提升国家整体的技术能力、制造能力和管理能力，一句话概括，即提升综合国力。可以说，数字化就是新型生产力。</span
      >
      <br />
      <br />
      <span>截取自中国社会科学院数量经济与技术经济研究所所长李平序</span>
    </div>
    <br />
    <br />
    <div class="catalogue">
      <p class="ptittle">02目录</p>
      <p><span>第一章</span>数字化管理体系</p>
      <p><span>第一节</span>数字体系</p>
      <p>一、数字经济 </p>
      <p>二、数字体系的概念 </p>
      <p>三、数字体系的顶层结构 </p>
      <p>四、新过程方法 </p>
      <p>五、管理体系数字化的普适性方法 </p>
      <p><span>第二节</span>管理体系结构的回顾</p>
      <p>一、元素树</p>
      <p>二、体系树</p>
      <p>三、工作结构表</p>
      <p><span>第三节</span>经营路线</p>
      <p>一、经营路线的策划</p>
      <p>二、过程分类</p>
      <p>三、业务路线图</p>
      <p>四、在经营路线上建立信息链</p>
      <p><span>第四节</span>新型价值链</p>
      <p>一、新型价值链结构</p>
      <p>二、价值链的活动类型</p>
      <p>三、价值链活动时间的确定</p>
      <p>四、价值链活动属性表</p>
      <p>五、价值链分析与优化</p>
      <p>六、经营路线与新型价值链的关系</p>
      <p>七、新型价值链与网络的关系</p>
      <p><span>第五节</span>信息扁平化</p>
      <p>一、管理扁平化的五个方面</p>
      <p>二、影响信息流速度的因素</p>
      <p>三、信息的三个维度</p>
      <p>四、信息扁平化指数</p>
      <p>五、信息扁平化的关键——信息权扁平化</p>
      <p>六、信息重要性等级</p>
      <p>七、单个信息的扁平化设计</p>
      <p><span>第六节</span>体系数字化平台</p>
      <p><span>第七节</span>体系创新</p>
      <p>一、制度与生产力</p>
      <p>二、基础性创新</p>
      <p>三、三类过程的创新顺序</p>
      <p><span>第二章</span>三元素</p>
      <p><span>第一节</span>三元素结构</p>
      <p>一、二元结构</p>
      <p>二、三元结构</p>
      <p>三、二元结构与三元结构</p>
      <p><span>第二节</span>过程单元</p>
      <p>一、过程单元图</p>
      <p>二、最小单元过程</p>
      <p>三、控制点</p>
      <p>四、零点</p>
      <p><span>第三节</span>链</p>
      <p>一、三元素的识别</p>
      <p>二、信息链</p>
      <p>三、数据链</p>
      <p>四、失效链</p>
      <p><span>第四节</span>网络体系</p>
      <p>一、元素边界</p>
      <p>二、基于过程的网络体系</p>
      <p>三、网络体系复杂度</p>
      <p>四、网络数字化</p>
      <p>五、网络体系的支持</p>
      <p>六、网络体系能力</p>
      <p><span>第三章</span>数字体系的过程设计</p>
      <p><span>第一节</span>效率倍增的过程方法</p>
      <p>一、过程方法</p>
      <p>二、过程相互作用</p>
      <p><span>第二节</span>过程设计模型</p>
      <p>一、过程六要素模型</p>
      <p>二、过程设计模型</p>
      <p>三、单一过程结构</p>
      <p>四、过程能力设计</p>
      <p><span>第三节</span>数字体系的文件格式</p>
      <p>一、新业务的文件格式</p>
      <p>二、金龟表文件格式</p>
      <p>三、文件网格格式</p>
      <p>四、两种格式的比较</p>
      <p>五、过程基本轮廓</p>
      <p><span>第四节</span>数字词典</p>
      <p>一、统一用词和术语</p>
      <p>二、术语的数字编码</p>
      <p>三、词组、句子与连接</p>
      <p><span>第五节</span>过程风险数字化</p>
      <p>一、过程风险识别及其数字代码</p>
      <p>二、过程风险的预防措施设计</p>
      <p>三、过程要求的开发</p>
      <p>四、过程失效数字化</p>
      <p><span>第六节</span>数字体系赋能</p>
      <p>一、体系聚能</p>
      <p>二、体系赋能</p>
      <p><span>第四章</span>过程数字化</p>
      <p><span>第一节</span>过程准则数字化</p>
      <p>一、过程准则的数字符号</p>
      <p>二、两个维度的要求</p>
      <p>三、体系成熟度</p>
      <p><span>第二节</span>输出数字化</p>
      <p>一、输出的数字符号</p>
      <p>二、输出频率的数字符号</p>
      <p><span>第三节</span>表格栏目数字化</p>
      <p>一、数据布局路线图</p>
      <p>二、表格通用性栏目的数字化</p>
      <p>三、数据链数字化</p>
      <p>四、表格关联栏目的数字化</p>
      <p><span>第四节</span>输入数字化</p>
      <p><span>第五节</span>输入与输出杠杆比</p>
      <p>一、过程输入与输出杠杆比</p>
      <p>二、体系输入与输出杠杆比</p>
      <p>三、效率与有效性</p>
      <p><span>第六节</span>过程活动数字化</p>
      <p>一、过程活动的数字符号</p>
      <p>二、活动频率的数字符号</p>
      <p><span>第七节</span>过程分工模型</p>
      <p>一、过程分工模型</p>
      <p>二、过程展开</p>
      <p>三、各活动类型的占比</p>
      <p>四、岗位活动数字化</p>
      <p><span>第八节</span>输出与活动关联数字化</p>
      <p>一、过程输出与活动比</p>
      <p>二、输出与岗位配比</p>
      <p>三、体系输出与活动和岗位配比</p>
      <p><span>第九节</span>体系能力</p>
      <p>一、统计的过程能力</p>
      <p>二、体系能力</p>
      <p><span>第五章</span>数字体系柔性化</p>
      <p><span>第一节</span>体系柔性化的由来</p>
      <p><span>第二节</span>体系标准单元</p>
      <p>一、柔性化的基础——标准个性化</p>
      <p>三、体系标准件的建设</p>
      <p>四、表单标准件</p>
      <p>五、输入输出标准件</p>
      <p>六、管理定式</p>
      <p>七、标准化程度</p>
      <p><span>第三节</span>体系碎片化</p>
      <p>一、体系碎片化的四个方面</p>
      <p>二、作业文件碎片化</p>
      <p>三、信息流碎片化</p>
      <p>四、软件碎片化</p>
      <p>五、体系碎片化的路径</p>
      <p><span>第四节</span>体系超市</p>
      <p>一、体系货架</p>
      <p>二、货架数字化</p>
      <p>三、体系碎片数字化</p>
      <p>四、体系标准单元版本</p>
      <p><span>第五节</span>体系集成</p>
      <p><span>第六节</span>第六节</p>
      <p>一、过程定制原理</p>
      <p>二、过程变化</p>
      <p><span>第七节</span>过程项目化</p>

      <p>一、过程项目</p>
      <p>二、过程项目的确定</p>
      <p>三、过程拥有者</p>
      <p>四、过程项目特征</p>
      <p><span>第六章</span>数字体系运行与绩效</p>
      <p><span>第一节</span>体系落地现代化</p>
      <p>一、体系落地与支持模型</p>
      <p>二、移动端操作</p>
      <p>三、无边界操作</p>
      <p>四、无纸化操作</p>
      <p>五、一站式体系</p>
      <p><span>第二节</span>战略实现部署</p>
      <p>一、战略阶梯</p>

      <p>二、战略任务与目标</p>
      <p>三、战略任务矩阵</p>
      <p>四、年度经营计划</p>
      <p><span>第三节</span>过程指标与要求</p>
      <p>一、常规指标</p>
      <p>二、指标系统</p>
      <p>三、常规指标权重的确定</p>
      <p>四、常规要求</p>
      <p><span>第四节</span>数字体系落地</p>
      <p>二、例外事落地</p>
      <p>三、知识体系支持</p>
      <p>四、数字体系落地支持平台</p>
      <p><span>第五节</span>体系运行大数据</p>
      <p>一、文件运行大数据</p>
      <p>二、目标指标运行大数据</p>
      <p>三、要求运行大数据</p>
      <p>四、成本运行大数据</p>
      <p><span>第六节</span>非业务指标业绩</p>
      <p>一、贡献度</p>
      <p>二、影响力</p>
      <p>三、转化率</p>
      <p>四、信用度</p>
      <p>五、个体利益最大化</p>
      <p><span>第七节</span>体系智能化</p>
      <p>一、体系智能化的进化</p>
      <p>二、智能化对传统管理职能的改变</p>
      <p><span>第七章</span>数字体系成熟度</p>
      <p><span>第一节</span>数字体系与智能制造</p>
      <p>一、数字体系与智能制造的关系</p>

      <p>二、管理体系水平等级</p>
      <p>三、数字体系与智能制造的匹配</p>
      <p><span>第二节</span>智能制造能力五级成熟度</p>
      <p>一、工业. 成熟度</p>
      <p>二、智能制造能力成熟度</p>
      <p>三、智能制造能力成熟度评价方法</p>
      <p><span>第三节</span>体系数字化转型的五个发展阶段</p>
      <p>一、数字化转型的五个发展阶段要求</p>
      <p>二、数字体系生态</p>
      <p><span>第四节</span>数字体系的三方面能力</p>
      <p>一、三方面的能力系统</p>
      <p>二、组织综合能力计算</p>
      <p>三、数字化转型的新型能力体系</p>
      <p>四、数字体系的性能</p>
      <p><span>第五节</span>体系数字化转型</p>
      <p>一、体系数字化转型步骤</p>
      <p>二、体系数字化转型意识</p>
      <p>参考文献</p>
    </div>
  </div>
</template>
    
    <script>
export default {};
</script>
    
    <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
.catalogue {
  color: #575757;
  font-size: 13.5px;
  line-height: 25px;
  span {
    margin-right: 15px;
  }
  p:after{
    content: '↓';
    color: rgb(149, 148, 148);
    margin-left: 5px;
  }
}
</style>